<template>
  <div class="d-inline-flex align-center justify-space-between">

    <v-btn
      icon
      small
      :disabled="loading || hasChanges || !prevPage"
      :title="prevPage && prevPage.title"
      @click="$emit('input', prevPage)"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <component
      :is="menuComponent"
      :close-on-content-click="false"
      :min-width="mini ? '100%' : 400"
      max-width="430"
      offset-y
      v-model="menu"
      @input="reset"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-small
          depressed
          height="32"
          color="primary"
          class="mx-1"
          :disabled="loading || !(academicYears.length > 0)"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            :small="!(title || subtitle)"
            :left="!!(title || subtitle)"
          >
            mdi-calendar-month-outline
          </v-icon>
          <div class="d-inline-flex flex-column">
            <span>{{ title }}</span>
            <span>{{ subtitle }}</span>
          </div>
        </v-btn>
      </template>

      <v-card v-if="academicYears.length > 0">
        <v-card-text class="pa-1">
          <v-row dense>
            <v-col cols="3">
              <v-subheader>Schuljahr:</v-subheader>
            </v-col>
            <v-col class="d-flex align-center flex-wrap py-2">
              <v-chip
                v-for="academicYear in academicYears"
                :key="academicYear.year"
                :color="academicYear === chosenAcademicYear ? 'primary' : null"
                :class="{'is-current': academicYear.isRunning()}"
                small
                @click="setChosenAcademicYear(academicYear)"
              >
                {{ academicYear.name }}
              </v-chip>
            </v-col>
          </v-row>

          <template v-if="allowSection && sections.length > 0">
            <v-divider />
            <v-row dense>
              <v-col cols="3">
                <v-subheader>Abschnitt:</v-subheader>
              </v-col>
              <v-col class="d-flex align-center flex-wrap py-2">
                <v-chip
                  v-for="section in sections"
                  :key="section['@id']"
                  :color="section === chosenSection ? 'primary' : null"
                  :class="{'is-current': section.isRunning()}"
                  :close="section === chosenSection"
                  small
                  @click="setChosenSection(section)"
                  @click:close="setChosenSection(null)"
                >
                  {{ section.nameShort }}
                </v-chip>
              </v-col>
            </v-row>
          </template>

          <template v-if="allowSection && chosenSection && tabs.length > 0">
            <v-divider />

            <v-tabs
              v-model="pageTypeTab"
              centered
              height="30"
              class="mt-1 mb-2"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.id"
              >{{ tab.name }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="pageTypeTab">

              <v-tab-item v-if="allowMonth">
                <v-row dense>
                  <v-col class="text-center py-2">
                    <v-chip
                      v-for="month in months"
                      :key="month.monthId"
                      :color="month === chosenMonth ? 'primary' : null"
                      :class="{'is-current': month.isCurrent}"
                      :close="month === chosenMonth"
                      small
                      @click="setChosenMonth(month)"
                      @click:close="setChosenMonth(null)"
                    >
                      {{ month.text }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item v-if="allowWeek">
                <v-row dense>
                  <v-col class="text-center py-2">
                    <v-chip
                      v-for="week in weeks"
                      :key="week.weekId"
                      :color="week === chosenWeek ? 'primary' : null"
                      :class="{'is-current': week.isCurrent}"
                      :close="week === chosenWeek"
                      small
                      @click="setChosenWeek(week)"
                      @click:close="setChosenWeek(null)"
                    >
                      {{ week.text }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item v-if="allowDay">
                <v-row dense>
                  <v-col class="text-center">
                    <v-date-picker
                      v-model="chosenDay"
                      :min="chosenSection.startsOn"
                      :max="chosenSection.endsOn"
                      :allowedDates="isDateAllowed"
                      first-day-of-week="1"
                      full-width
                      no-title
                      show-week
                      color="primary"
                    ></v-date-picker>
                    <v-btn
                      v-if="chosenSection.isRunning()"
                      small
                      text
                      @click="setChosenDateToday()"
                    >
                      Heute
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>

            </v-tabs-items>
          </template>

        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-center">
          <v-btn
            :color="hasChanges ? 'primary' : null"
            :text="!hasChanges"
            small
            @click="submit"
          >
            {{ hasChanges ? 'Anwenden' : 'Schließen' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </component>

    <v-btn
      icon
      small
      :disabled="loading || hasChanges || !nextPage"
      :title="nextPage && nextPage.title"
      @click="$emit('input', nextPage)"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>

  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import { VBottomSheet, VMenu } from 'vuetify/lib'
import { dateFromString, dateToString, formatDate } from '@/helpers/datetime'


export default {
  name: 'DateRangePicker',
  components: {
    VBottomSheet,
    VMenu
  },
  props: {
    loading: Boolean,
    value: {
      type: Object,
      default: () => ({
        academicYear: null,
        section: null,
        startDate: null,
        endDate: null
      })
    },
    allowSection: {
      type: Boolean,
      default: true
    },
    allowMonth: {
      type: Boolean,
      default: true
    },
    allowWeek: {
      type: Boolean,
      default: true
    },
    allowDay: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    menu: false,
    pageTypeTab: null,
    chosenAcademicYear: null,
    chosenSection: null,
    chosenStartDate: null,
    chosenEndDate: null
  }),
  computed: {
    ...mapGetters('schoolCalendar', [
      'monthsBySection',
      'weeksBySection',
      'allowedDatesBySection'
    ]),
    menuComponent() {
      return this.mini ? VBottomSheet : VMenu
    },
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    tabs() {
      return [
        {id: 'month', name: 'Monat', allowed: this.allowMonth},
        {id: 'week', name: 'Woche', allowed: this.allowWeek},
        {id: 'day', name: 'Tag', allowed: this.allowDay}
      ].filter(t => t.allowed)
    },
    academicYears() {
      return this.$store.state.common.academicYears
    },
    sections() {
      if (!this.chosenAcademicYear) return []
      const yearIri = this.chosenAcademicYear['@id']
      return this.$store.state.common.sections.filter(s => s.academicYear === yearIri)
    },
    months() {
      return this.monthsBySection(this.chosenSection)
    },
    chosenMonth() {
      const { chosenStartDate, chosenEndDate } = this
      if (!(chosenStartDate && chosenEndDate)) return null
      return this.months.find(m => chosenStartDate === m.startsOn && chosenEndDate === m.endsOn)
    },
    weeks() {
      return this.weeksBySection(this.chosenSection)
    },
    chosenWeek() {
      const { chosenStartDate, chosenEndDate } = this
      if (!(chosenStartDate && chosenEndDate)) return null
      return this.weeks.find(w => chosenStartDate === w.startsOn && chosenEndDate === w.endsOn)
    },
    chosenDay: {
      get() {
        const { chosenStartDate, chosenEndDate } = this
        return chosenStartDate === chosenEndDate && chosenStartDate || null
      },
      set(value) {
        this.chosenStartDate = this.chosenEndDate = value
      }
    },
    allowedDates() {
      return this.allowedDatesBySection(this.chosenSection)
    },
    siblingPage() {
      return offset => {
        const findSibling = (cur, arr) => arr[arr.indexOf(cur) + offset]
        let {
          chosenAcademicYear: academicYear,
          chosenSection: section,
          chosenStartDate: startDate,
          chosenEndDate: endDate
        } = this
        let title
        if (this.chosenDay) {
          const date = dateFromString(startDate)
          do {
            date.setDate(date.getDate() + offset)
            startDate = endDate = dateToString(date)
            if (!section.isRunning(startDate)) return null
          } while (this.allowedDates.length > 0 && !this.isDateAllowed(startDate))
          title = formatDate(date, {weekday: 'short', day: 'numeric', month: 'short'})
        }
        else if (this.chosenWeek) {
          const week = findSibling(this.chosenWeek, this.weeks)
          if (!week) return null
          startDate = week.startsOn
          endDate = week.endsOn
          title = week.text
        }
        else if (this.chosenMonth) {
          const month = findSibling(this.chosenMonth, this.months)
          if (!month) return null
          startDate = month.startsOn
          endDate = month.endsOn
          title = month.text
        }
        else if (this.chosenSection) {
          section = findSibling(section, this.sections)
          if (!section) return null
          startDate = endDate = null
          title = `${section.nameShort}, ${section.calendarYear}`
        }
        else if (this.chosenAcademicYear) {
          academicYear = findSibling(academicYear, this.academicYears)
          if (!academicYear) return null
          section = startDate = endDate = null
          title = academicYear.name
        }
        else {
          return null
        }
        return {academicYear, section, startDate, endDate, title}
      }
    },
    prevPage() {
      return this.siblingPage(-1)
    },
    nextPage() {
      return this.siblingPage(1)
    },
    title() {
      if (this.chosenDay) return formatDate(this.chosenDay, {weekday: 'short', day: 'numeric', month: 'short'})
      if (this.chosenWeek) return this.chosenWeek.text
      return this.chosenMonth?.text || null
    },
    subtitle() {
      if (this.chosenSection) return `${this.chosenSection.nameShort}, ${this.chosenSection.calendarYear}`
      return this.chosenAcademicYear?.name || null
    },
    hasChanges() {
      return this.chosenEndDate !== this.value.endDate
        || this.chosenStartDate !== this.value.startDate
        || this.chosenSection !== this.value.section
        || this.chosenAcademicYear !== this.value.academicYear
    }
  },
  watch: {
    value() {
      this.reset()
    },
    chosenSection(section, oldSection) {
      if (section && section.startsOn !== oldSection?.startsOn) {
        this.chosenDay = section.startsOn
      }
    }
  },
  created() {
    this.reset()
  },
  methods: {
    reset() {
      const { academicYear, section, startDate, endDate } = this.value
      this.chosenAcademicYear = academicYear
      this.chosenSection = section
      this.chosenStartDate = startDate
      this.chosenEndDate = endDate

      if (this.chosenDay && this.allowDay) {
        this.pageTypeTab = this.tabs.findIndex(t => t.id === 'day')
      }
      else if (this.chosenWeek && this.allowWeek) {
        this.pageTypeTab = this.tabs.findIndex(t => t.id === 'week')
      }
      else {
        this.pageTypeTab = 0
      }
    },
    setChosenAcademicYear(academicYear) {
      this.chosenAcademicYear = academicYear
      this.chosenSection = this.chosenStartDate = this.chosenEndDate = null
    },
    setChosenSection(section) {
      this.chosenSection = section
      this.chosenStartDate = this.chosenEndDate = null
    },
    setChosenMonth(month) {
      this.chosenStartDate = month?.startsOn || null
      this.chosenEndDate = month?.endsOn || null
    },
    setChosenWeek(week) {
      this.chosenStartDate = week?.startsOn || null
      this.chosenEndDate = week?.endsOn || null
    },
    setChosenDateToday() {
      this.chosenStartDate = this.chosenEndDate = dateToString(new Date())
    },
    isDateAllowed(date) {
      return this.allowedDates.includes(date)
    },
    submit() {
      if (this.hasChanges) {
        this.$emit('input', {
          academicYear: this.chosenAcademicYear,
          section: this.chosenSection,
          startDate: this.chosenStartDate,
          endDate: this.chosenEndDate
        })
      }
      this.menu = false
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.v-chip {
  margin: 1px;

  &.v-chip--no-color.is-current {
    border: 1px solid $color-primary;
    color: $color-primary;
  }
}
</style>
