<template>
  <AppBar>
    <v-btn
      v-if="canWriteStudentAbsenceByStudent()"
      x-small
      depressed
      height="32"
      color="primary"
      class="mx-2"
      title="Abwesenheit melden"
      @click="$emit('show:register-student-absence-dialog')"
    >
      <v-icon small>mdi-account-alert-outline</v-icon>
    </v-btn>

    <ContextObjectSelect
      v-model="contextObject"
      :loading="loading"
      :filter-collections="['schoolClasses', 'courses']"
    />

    <span class="ml-2" />

    <GroupBySelect
      :loading="loading"
      :value="groupBy"
      @input="$emit('input:group-by', $event || null)"
    />

    <DateRangePicker
      v-if="!mini"
      :value="dateInterval"
      :allow-month="'section' !== groupBy"
      :allow-week="!['section', 'month'].includes(groupBy)"
      :allow-day="!['section', 'month', 'week'].includes(groupBy)"
      :loading="loading"
      class="ml-4"
      @input="$emit('input:date-interval', $event)"
    />
  </AppBar>
</template>


<script>
import AppBar from '@/components/app-bar'
import ContextObjectSelect from '@/components/context-object-select'
import DateRangePicker from '@/components/date-range-picker'
import GroupBySelect from '@/pages/absences.page/group-by-select'

export default {
  name: 'AbsencesTableToolbarTop',
  components: {
    AppBar,
    ContextObjectSelect,
    GroupBySelect,
    DateRangePicker
  },
  props: {
    loading: Boolean,
    mini: Boolean,
    groupBy: String,
    dateInterval: Object
  },
  computed: {
    canWriteStudentAbsenceByStudent() {
      return this.$store.getters.canWriteStudentAbsenceByStudent
    },
    contextObject: {
      get() {
        return this.$store.state.contextObject
      },
      set(contextObject) {
        this.$emit('input:context-object', contextObject)
      }
    }
  }
}
</script>
