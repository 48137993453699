<template>
  <span>
    {{ value.code }}
    {{ value.name }}
  </span>
</template>


<script>
export default {
  name: 'ItemClassroom',
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>
