<template>
  <div class="d-flex flex-column fill-height">
    <ToolbarTop
      :loading="loading"
      :mini="mini"
      :group-by="groupBy"
      :date-interval="dateInterval"
      @input:context-object="setContextObject"
      @input:group-by="setGroupBy"
      @input:date-interval="setDateInterval"
      @show:register-student-absence-dialog="showRegisterStudentAbsenceDialog = true"
    />

    <v-card class="flex-grow-1 ma-1 px-2 py-1 overflow-auto print-no-shadow print-no-border">
      <template v-if="studentAbsencesSummary">
        <TableCaption
          :start-date="studentAbsencesSummary.firstDayDate || startDate"
          :end-date="studentAbsencesSummary.lastDayDate || endDate"
          :filter-by-student="filterByStudent"
          :filter-by-school-subject="filterBySchoolSubject"
          :loading="loading"
          @clear:filter-by-student="toggleFilterByStudent()"
          @clear:filter-by-school-subject="toggleFilterBySchoolSubject()"
          @refresh="loadStudentAbsencesSummary()"
        />

        <AbsencesTable
          :value="studentAbsencesSummary"
          :group-by="groupBy"
          @input:month="setMonth"
          @input:week="setWeek"
          @input:day="setDay"
          @input:filter-by-school-subject="toggleFilterBySchoolSubject"
          @input:filter-by-student="toggleFilterByStudent"
          @input:context-object="setContextObject"
          @refresh="loadStudentAbsencesSummary()"
        />
      </template>
    </v-card>

    <ToolbarBottom
      v-if="mini"
      :loading="loading"
      :date-interval="dateInterval"
      :group-by="groupBy"
      @input:date-interval="setDateInterval"
    />

    <AppLoader v-show="loading" />

    <RegisterStudentAbsenceDialog
      v-if="canWriteStudentAbsenceByStudent()"
      :show="showRegisterStudentAbsenceDialog"
      @close="closeRegisterStudentAbsenceDialog"
    />
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { api } from '@/api'
import { dateFromString, firstDateOfWeek } from '@/helpers/datetime'
import AppLoader from '@/components/app-loader'
import AbsencesTable from '@/pages/absences.page/absences-table'
import RegisterStudentAbsenceDialog from '@/pages/absences.page/register-student-absence-dialog'
import TableCaption from '@/pages/absences.page/table-caption'
import ToolbarBottom from '@/pages/absences.page/toolbar-bottom'
import ToolbarTop from '@/pages/absences.page/toolbar-top'

export default {
  name: 'AbsencesPage',
  components: {
    AbsencesTable,
    AppLoader,
    RegisterStudentAbsenceDialog,
    TableCaption,
    ToolbarBottom,
    ToolbarTop
  },
  data: () => ({
    loadingStudentAbsencesSummary: null,
    groupBy: null,
    filterBySchoolSubject: null,
    filterByStudent: null,
    studentAbsencesSummary: null,
    showRegisterStudentAbsenceDialog: false
  }),
  computed: {
    ...mapState('common', [
      'sections',
      'schoolClasses'
    ]),
    ...mapState(['contextObject']),
    ...mapState('schoolCalendar', [
      'academicYear',
      'section',
      'startDate',
      'endDate'
    ]),
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    loading() {
      return this.loadingStudentAbsencesSummary || this.$store.getters['common/loading']
    },
    dateInterval() {
      const { academicYear, section, startDate, endDate } = this
      return {academicYear, section, startDate, endDate}
    },
    isContextSchoolClass() {
      return 'SchoolClass' === this.contextObject?.['@type']
    },
    canWriteStudentAbsenceByStudent() {
      return this.$store.getters.canWriteStudentAbsenceByStudent
    },
    showWelcomePage() {
      const state = this.$store.state.common
      return state.academicYears.length == 0
        || state.sections.length == 0
        || state.schoolSubjects.length == 0
    }
  },
  watch: {
    contextObject() {
      this.filterByStudent = null
    },
    isContextSchoolClass () {
      this.filterBySchoolSubject = null
    }
  },
  async created() {
    const { dispatch } = this.$store
    await this.loadCommonData()
    if (this.showWelcomePage) {
      this.$router.push({name: 'welcome'})
      return
    }
    await this.resetDateInterval()
    await Promise.all([
      dispatch('common/fetchSchoolClassesByAcademicYear', this.academicYear),
      dispatch('common/fetchCoursesBySection', this.section)
    ])
    this.resetContextObject()
    this.loadStudentAbsencesSummary()
  },
  methods: {
    loadCommonData() {
      return this.$store.dispatch('common/fetchCollectionOnce', [
        'absenceReasons',
        'academicYears',
        'schoolSubjects',
        'sections'
      ])
    },
    async loadStudentAbsencesSummary() {
      await this.$nextTick()
      this.studentAbsencesSummary = null
      if (!this.contextObject) return
      const groupBy = this.groupBy || undefined
      const needSection = !groupBy || 'day' == groupBy
      const section = this.section?.['@id'] || undefined
      if (needSection && !section) return
      const url = `${this.contextObject['@id']}/student_absences_summary`
      const startDate = section && this.startDate
      const endDate = startDate && this.endDate
      const params = {
        section,
        startDate,
        endDate,
        groupBy,
        schoolSubject: this.isContextSchoolClass && this.filterBySchoolSubject?.['@id'] || undefined,
        student: this.filterByStudent?.student
      }
      this.loadingStudentAbsencesSummary = true
      try {
        this.studentAbsencesSummary = (await api.get(url, {params})).data
      } catch (e) {
        this.studentAbsencesSummary = null
        console.error(e)
      } finally {
        this.loadingStudentAbsencesSummary = false
      }
    },
    resetContextObject() {
      this.$store.dispatch('resetContextObject', ['SchoolClass', 'Course'])
    },
    setContextObject(contextObject) {
      this.$store.commit('SET_CONTEXT_OBJECT', contextObject)
      this.loadStudentAbsencesSummary()
    },
    async setGroupBy(groupBy) {
      if (this.groupBy === groupBy) return
      this.groupBy = groupBy
      this.studentAbsencesSummary = null
      await this.resetDateInterval()
      this.loadStudentAbsencesSummary()
    },
    async setDateInterval(value) {
      const { commit, dispatch } = this.$store
      const { academicYear, section } = value || {}
      const promises = []
      if (academicYear && academicYear.year !== this.academicYear?.year) {
        promises.push(
          dispatch('common/fetchSchoolClassesByAcademicYear', academicYear)
        )
      }
      if (section && section['@id'] !== this.section?.['@id']) {
        promises.push(
          dispatch('common/fetchDayPlansBySection', section),
          dispatch('common/fetchCoursesBySection', section)
        )
      }
      commit('schoolCalendar/SET_STATE', value)
      await Promise.all(promises)
      this.resetContextObject()
      this.loadStudentAbsencesSummary()
    },
    async resetDateInterval() {
      const { dispatch } = this.$store
      const { groupBy } = this

      await dispatch('schoolCalendar/setNearestAcademicYear')
      if (!this.academicYear || ['section', 'month'].includes(groupBy)) return

      await dispatch('schoolCalendar/setNearestSection')
      if (!this.section || ['month', 'week'].includes(groupBy)) return

      await dispatch('common/fetchDayPlansBySection', this.section)

      const action = 'day' === groupBy ? 'setNearestSchoolMonth' : 'setNearestSchoolWeek'
      dispatch(`schoolCalendar/${action}`)
    },
    setMonth(month) {
      if (!month?.monthId) return
      this.$store.dispatch('schoolCalendar/setNearestSchoolMonth', dateFromString(`${month.monthId}-01`))
      this.loadStudentAbsencesSummary()
    },
    setWeek(week) {
      if (!week?.weekId) return
      const [ , y, w ] = week.weekId.match(/^(\d{4})-W(\d{2})$/)
      this.$store.dispatch('schoolCalendar/setNearestSchoolWeek', firstDateOfWeek(+y, +w))
      this.loadStudentAbsencesSummary()
    },
    setDay({ date }) {
      this.$store.commit('schoolCalendar/SET_DATES', date)
      this.loadStudentAbsencesSummary()
    },
    toggleFilterBySchoolSubject(schoolSubject) {
      this.filterBySchoolSubject = !this.filterBySchoolSubject && schoolSubject || null
      this.loadStudentAbsencesSummary()
    },
    toggleFilterByStudent(student) {
      this.filterByStudent = !this.filterByStudent && student || null
      this.loadStudentAbsencesSummary()
    },
    closeRegisterStudentAbsenceDialog(refresh = false) {
      if (refresh) this.loadStudentAbsencesSummary()
      this.showRegisterStudentAbsenceDialog = false
    }
  }
}
</script>
