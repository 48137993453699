<template>
  <component
    v-model="menu"
    :is="menuComponent"
    min-width="150"
    max-width="430"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        depressed
        height="32"
        color="primary"
        v-bind="attrs"
        v-on="on"
        :disabled="loading"
      >
        <v-icon
          :small="!text"
          :left="!!text"
        >
          mdi-arrow-expand-horizontal
        </v-icon>
        {{ text }}
      </v-btn>
    </template>

    <v-card>
      <v-list dense>
        <v-subheader>ANSICHT</v-subheader>
        <v-list-item-group
          :value="value"
          color="primary"
          @change="$emit('input', $event); menu = false"
        >
          <v-list-item
            v-for="option in options"
            :key="option.value"
            :value="option.value"
          >
            <v-list-item-title>{{ option.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </component>
</template>


<script>
import { VBottomSheet, VMenu } from 'vuetify/lib'

export default {
  name: 'GroupBySelect',
  components: {
    VBottomSheet,
    VMenu
  },
  props: {
    value: {
      type: String,
      default: null
    },
    loading: Boolean
  },
  data: () => ({
    menu: false
  }),
  computed: {
    options() {
      return [
        {value: null, text: 'Stunde'},
        {value: 'day', text: 'Tag'},
        {value: 'week', text: 'Woche'},
        {value: 'month', text: 'Monat'},
        {value: 'section', text: 'Abschnitt'}
      ]
    },
    selectedOption() {
      return this.options.find(o => o.value == this.value)
    },
    text() {
      return !this.mini && this.selectedOption?.text || null
    },
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    menuComponent() {
      return this.mini ? VBottomSheet : VMenu
    }
  }
}
</script>
