const GENDER_ICON = {
  3: 'mdi-face',
  4: 'mdi-face-woman'
}


export default class Student {
  constructor(rawData) {
    Object.assign(this, rawData)
    const { firstName, lastName, gender } = this
    this.displayName = `${lastName}, ${firstName}`
    this.displayNameShort = `${lastName} ${firstName.charAt(0)}`
    this.icon = GENDER_ICON[gender] || 'mdi-face'
  }
}
