<template>
  <v-select
    :value="value"
    :items="items"
    item-text="displayName"
    label="Schüler/-in"
    return-object
    multiple
    outlined
    dense
    hide-details
    v-on="$listeners"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        v-if="index === 0"
        small
      >
        <span>{{ item.displayName }}</span>
      </v-chip>
      <span
        v-if="index === 1"
        class="grey--text text-caption"
      >
        (+{{ value.length - 1 }} weiteren)
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox v-model="selectAll"></v-checkbox>
        </v-list-item-action>
        <v-list-item-title>
          Alle
        </v-list-item-title>
      </v-list-item>
      <v-divider />
    </template>
  </v-select>
</template>


<script>
export default {
  name: 'StudentSelect',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectAll: {
      get() {
        return this.value.length === this.items.length
      },
      set(value) {
        this.$emit('change', value ? [...this.items] : [])
      }
    }
  }
}
</script>
