<template>
  <span class="text-no-wrap">
    <span class="font-weight-bold">
      {{ item.hourNumber }}
    </span>
    <span class="grey--text text-caption pl-1">
      [{{ item.startsAt | trimZeroesLeft }} - {{ item.endsAt | trimZeroesLeft }}]
    </span>
  </span>
</template>


<script>
import { trimZeroesLeft } from '@/helpers/string'

export default {
  name: 'HourNumberSelectItem',
  filters: {
    trimZeroesLeft
  },
  props: {
    item: Object
  }
}
</script>
