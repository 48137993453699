<template>
  <div class="component">
    <table @keyup.esc="clearSelection()">

      <thead>
        <tr>
          <th rowspan="5"></th>
          <th
            v-if="isContextCourse"
            rowspan="5"
          ></th>
          <th rowspan="5"></th>
          <th rowspan="5"></th>
          <th rowspan="5"></th>
          <th rowspan="5"></th>
          <template v-if="showColYears">
            <th
              v-for="colYear in colYears"
              :key="colYear.year"
              :colspan="colYear.colspan"
              :class="{'primary--text': colYear.current}"
            >
              <span class="date-interval">
                {{ colYear.year }}
              </span>
            </th>
          </template>
        </tr>
        <tr>
          <template v-if="showColSections">
            <th
              v-for="colSection in colSections"
              :key="colSection.id"
              :colspan="colSection.colspan"
              :class="{'primary--text': colSection.current}"
            >
              <span class="date-interval">
                {{ colSection.title }}
              </span>
            </th>
          </template>
        </tr>
        <tr>
          <template v-if="showColMonths">
            <th
              v-for="colMonth in colMonths"
              :key="colMonth.id"
              :colspan="colMonth.colspan"
              :class="{'primary--text': colMonth.current}"
            >
              <button
                class="date-interval"
                v-text="colMonth.title"
                @click="$emit('input:month', colMonth)"
              />
            </th>
          </template>
        </tr>
        <tr>
          <template v-if="showColWeeks">
            <th
              v-for="colWeek in colWeeks"
              :key="colWeek.id"
              :colspan="colWeek.colspan"
              :class="{'primary--text': colWeek.current}"
            >
              <button
                class="date-interval"
                v-text="colWeek.title"
                @click="$emit('input:week', colWeek)"
              />
            </th>
          </template>
        </tr>
        <tr>
          <template v-if="showColDays">
            <th
              v-for="colDay in colDays"
              :key="colDay.id"
              :colspan="colDay.colspan"
              :class="{'primary--text': colDay.current}"
            >
              <button
                class="date-interval"
                v-text="colDay.title"
                @click="$emit('input:day', colDay)"
              />
            </th>
          </template>
        </tr>
        <tr>
          <th class="br-2">
            Name
          </th>
          <th
            v-if="isContextCourse"
            class="br-2"
          >
            Klasse
          </th>
          <th
            class="br-1"
            title="Stunden"
          >
            St.
          </th>
          <th
            class="br-1"
            title="Fehlstunden"
          >
            FSt.
          </th>
          <th
            class="br-1"
            title="Fehlstunden unentschuldigt"
          >
            UESt.
          </th>
          <th
            class="br-2"
            title="Verspätungen, Minuten"
          >
            VSMin.
          </th>
          <template v-if="!groupBy">
            <th
              v-for="(slot, slotIndex) in colSlots"
              :key="slot.slotId"
              :class="{'bl-1': slotIndex > 0 && dayId !== slot.dayId}"
              :set="dayId = slot.dayId"
              class="th-slot"
            >
              <div
                :title="`${slot.startsAt} - ${slot.endsAt}`"
                :class="{'primary--text': slot.current}"
              >
                {{ slot.hourNumber }}
              </div>
              <div
                v-for="(schoolSubject, index) in slot.schoolSubjects"
                :key="index"
              >
                <button
                  v-if="schoolSubject"
                  :title="schoolSubject.name"
                  :style="{backgroundColor: schoolSubject.color}"
                  :disabled="isContextCourse"
                  class="school-subject-btn rounded-pill px-1"
                  @click="$emit('input:filter-by-school-subject', schoolSubject)"
                >
                  {{ schoolSubject.code }}
                </button>
                <span v-else>?</span>
              </div>
            </th>
          </template>
          <template v-else>
            <th
              v-for="col in baseCols"
              :key="col.id"
              :colspan="col.daysCount || 1"
              :class="{'primary--text': col.current}"
              class="bl-1"
              v-text="col.title"
            />
          </template>
        </tr>
      </thead>

      <tbody ref="tbody">
        <tr
          v-for="(studentItem, studentIndex) in studentItems"
          :key="studentItem.student"
          :class="{selected: isRowSelected(studentIndex)}"
        >
          <th class="br-2 text-no-wrap">
            <button @click="$emit('input:filter-by-student', studentItem)">
              {{ studentItem.displayName }}
            </button>
          </th>
          <th
            v-if="isContextCourse"
            class="br-2"
          >
            <button @click="$emit('input:context-object', studentItem.schoolClass)">
              {{ studentItem.schoolClass.code }}
            </button>
          </th>
          <th>
            {{ studentItem.actualLessonsCount || null }}
          </th>
          <th>
            {{ studentItem.isAbsentCount || null }}
          </th>
          <th>
            {{ studentItem.isAbsentUnexcusedCount || null }}
          </th>
          <th>
            {{ studentItem.cameLaterSum || null }}
          </th>
          <template v-if="!groupBy">
            <td
              v-for="(slot, slotIndex) in value.slots"
              :key="slot.slotId"
              class="td-slot"
              :class="{
                'bl-1': slotIndex > 0 && dayId !== slot.dayId,
                ...selectionClasses(studentIndex, slotIndex)
              }"
              :set1="studentSlot = studentItem.slots[slotIndex]"
              :set2="dayId = slot.dayId"
              :set3="day = value.days.find(d => d.dayId === dayId)"
            >
              <button
                v-if="studentSlot.actualLesson"
                :class="{'is-future': slot.future}"
                @click="setSelection(studentIndex, slotIndex, $event.shiftKey)"
                @contextmenu="onContextmenu($event, studentIndex, slotIndex)"
              >
                <AbsenceChip
                  v-if="studentSlot.studentAbsence"
                  :student-absence="studentSlot.studentAbsence"
                  :show-icon="false"
                />
                <v-icon
                  v-else
                  size="18"
                  :color="slot.past ? 'error' : (slot.current ? 'primary' : null)"
                  v-text="day.past ? 'mdi-help-circle-outline' : 'mdi-circle-small'"
                />
              </button>
            </td>
          </template>
          <template v-else>
            <td
              v-for="(col, index) in baseCols"
              :key="col.id"
              :colspan="col.daysCount || 1"
              :set1="studentCol = studentCols(studentItem)[index]"
              class="bl-1"
            >
              {{ studentCol.isAbsentCount || null }}
              <sup>{{ studentCol.cameLaterSum && `+${studentCol.cameLaterSum}` || null }}</sup>
            </td>
          </template>
        </tr>
      </tbody>

    </table>

    <SelectionToolbar
      :can-edit="hasSelectionActualLessons"
      :can-delete="hasSelectionStudentAbsences"
      :style="selectionToolbarPosition"
      @click:edit="showDialog = 'EditDialog'"
      @click:delete="showDialog = 'DeleteDialog'"
      @click:close="clearSelection()"
    />

    <component
      :is="showDialog"
      :data="dialogData"
      @close="onCloseDialog($event)"
    />

  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import { formatDate, monthShortName } from '@/helpers/datetime'
import AbsenceChip from '@/components/edit-lesson-absences-dialog/absence-chip'
import SelectionToolbar from '@/pages/absences.page/absences-table/selection-toolbar'
import EditDialog from '@/pages/absences.page/absences-table/edit-dialog'
import DeleteDialog from '@/pages/absences.page/absences-table/delete-dialog'

export default {
  name: 'AbsencesTable',
  components: {
    AbsenceChip,
    SelectionToolbar,
    EditDialog,
    DeleteDialog
  },
  props: {
    value: Object,
    groupBy: String
  },
  data: () => ({
    selection: [],
    showDialog: null
  }),
  computed: {
    ...mapState(['contextObject']),
    ...mapState('common', [
      'sectionsMap'
    ]),
    ...mapGetters('common', [
      'schoolClassByIri',
      'schoolSubjectByIri'
    ]),
    isContextSchoolClass() {
      return 'SchoolClass' === this.contextObject?.['@type']
    },
    isContextCourse() {
      return 'Course' === this.contextObject?.['@type']
    },
    colSlots() {
      return (this.value?.slots || []).map(slot => ({
        ...slot,
        schoolSubjects: slot.schoolSubjects.map(this.schoolSubjectByIri)
      }))
    },
    colDays() {
      return (this.value?.days || []).map(day => ({
        ...day,
        id: day.dayId,
        title: formatDate(day.date, {weekday: 'short', day: 'numeric'}),
        colspan: day[this.groupBy ? 1 : 'slotsCount']
      }))
    },
    showColDays() {
      return 'day' !== this.groupBy && this.colDays.length > 1
    },
    colWeeks() {
      return (this.value?.weeks || []).map(week => ({
        ...week,
        id: week.weekId,
        title: week.weekId.replace(/^\d{4}-W0?/, 'KW'),
        colspan: week[this.groupBy ? 'daysCount' : 'slotsCount']
      }))
    },
    showColWeeks() {
      return 'week' !== this.groupBy && this.colWeeks.length > 1
    },
    colMonths() {
      return (this.value?.months || []).map(month => ({
        ...month,
        id: month.monthId,
        title: monthShortName(month.monthId),
        colspan: month[this.groupBy ? 'daysCount' : 'slotsCount']
      }))
    },
    showColMonths() {
      return 'month' !== this.groupBy && this.colMonths.length > 1
    },
    colSections() {
      return (this.value?.sectionItems || []).map(sectionItem => {
        const section = this.sectionsMap.get(sectionItem.section)
        return {
          ...sectionItem,
          id: section['@id'],
          section,
          title: section.name,
          colspan: sectionItem[this.groupBy ? 'daysCount' : 'slotsCount']
        }
      })
    },
    showColSections() {
      return 'section' !== this.groupBy && this.colSections.length > 1
    },
    colYears() {
      const colYearsMap = new Map()
      for (const colSection of this.colSections) {
        const year = colSection.section.startsOn.slice(0, 4)
        if (!colYearsMap.has(year)) {
          colYearsMap.set(year, {
            year,
            current: false,
            colspan: 0
          })
        }
        const colYear = colYearsMap.get(year)
        colYear.current = colYear.current || colSection.current
        colYear.colspan += colSection.colspan
      }
      return [...colYearsMap.values()]
    },
    showColYears() {
      return this.colYears.length > 1
    },
    baseCols() {
      const { groupBy } = this
      if (!groupBy) return this.value?.slots || []
      if ('day' == groupBy) return this.colDays
      if ('week' == groupBy) return this.colWeeks
      if ('month' == groupBy) return this.colMonths
      return this.colSections
    },
    studentItems() {
      const { studentItems = [] } = this.value || {}
      if (this.isContextSchoolClass) return studentItems
      return studentItems.map(studentItem => {
        const { schoolClass } = studentItem
        return {
          ...studentItem,
          schoolClass: schoolClass && this.schoolClassByIri(schoolClass) || {}
        }
      })
    },
    studentCols() {
      const group = (this.groupBy || 'slot') + ('section' == this.groupBy ? 'Items' : 's')
      return studentItem => studentItem[group]
    },
    isRowSelected() {
      return studentIndex => this.selection[0] === studentIndex
    },
    selectionClasses() {
      return (studentIndex, slotIndex) => {
        if (!this.isRowSelected(studentIndex)) return null
        const isCellSelected = this.selection[1] <= slotIndex && slotIndex <= this.selection[2]
        return {
          'selection': isCellSelected,
          'selection-first': isCellSelected && this.selection[1] === slotIndex,
          'selection-last': isCellSelected && this.selection[2] === slotIndex
        }
      }
    },
    selectionToolbarPosition() {
      const [ studentIndex, firstIndex, lastIndex ] = this.selection
      if (null == studentIndex) return {display: 'none'}
      const tr = this.$refs.tbody.children[studentIndex]
      const slots = tr.querySelectorAll('.td-slot')
      const { offsetLeft } = slots[firstIndex]
      const tdLast = slots[lastIndex]
      const left = (offsetLeft + tdLast.offsetLeft + tdLast.offsetWidth) / 2
      return {top: `${tr.offsetTop}px`, left: `${left}px`}
    },
    selectedStudentItem() {
      return this.studentItems[this.selection[0]] || null
    },
    selectedSlots() {
      return this.selectedStudentItem?.slots?.slice(this.selection[1], this.selection[2] + 1) || null
    },
    hasSelectionActualLessons() {
      return this.selectedSlots?.some(s => s.actualLesson)
    },
    hasSelectionStudentAbsences() {
      return this.selectedSlots?.some(s => s.studentAbsence)
    },
    dialogData() {
      const start = this.colSlots[this.selection[1]]
      const end = this.colSlots[this.selection[2]]
      const { studentAbsence } = this.selectedSlots?.find(s => s.studentAbsence) || {}
      return this.showDialog && {
        students: [this.selectedStudentItem],
        allStudents: this.studentItems,
        startDate: start.date,
        startHourNumber: start.hourNumber,
        endDate: end.date,
        endHourNumber: end.hourNumber,
        studentAbsence
      }
    }
  },
  watch: {
    value() {
      this.clearSelection()
    }
  },
  methods: {
    clearSelection() {
      this.selection = []
    },
    setSelection(studentIndex, slotIndex, span) {
      if (span && studentIndex === this.selection[0] && this.selection[1] != null) {
        this.selection = [
          studentIndex,
          Math.min(slotIndex, this.selection[1]),
          slotIndex < this.selection[1] ? this.selection[2] : slotIndex
        ]
      } else if (studentIndex === this.selection[0] && slotIndex === this.selection[1] && slotIndex === this.selection[2]) {
        this.clearSelection()
      } else {
        this.selection = [studentIndex, slotIndex, slotIndex]
      }
    },
    onContextmenu(event, studentIndex, slotIndex) {
      event.preventDefault()
      this.setSelection(studentIndex, slotIndex, true)
    },
    onCloseDialog(refresh = false) {
      this.showDialog = null
      if (refresh) {
        this.$emit('refresh')
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

$thin-border: 1px solid map-get($material-theme, 'dividers');
$thick-border: 2px solid rgba(0, 0, 0, 0.24);
$selection-border: 3px solid $color-primary;
$selection-border-radius: 4px;

.component {
  position: relative;
}

table {
  font-size: 11px;
  text-align: center;

  thead tr:last-child th {
    border-bottom: $thick-border;
  }

  thead .rounded-pill {
    line-height: 15px;
  }

  tbody {
    th, td {
      border-top: $thin-border;
    }

    th {
      border-right: $thin-border;
      min-width: 32px;

      &:last-of-type {
        border-right: $thick-border;
      }
    }
  }
}

table, tr, th, td {
  border-collapse: collapse;
}

table th,
table td {
  height: 28px;
  padding: 2px;

  &.br-1 {
    border-right: $thin-border;
  }

  &.bl-1 {
    border-left: $thin-border;
  }

  &.br-2 {
    border-right: $thick-border;
  }
}

.th-slot {
  vertical-align: top;
  min-width: 30px;
}

.date-interval {
  display: inline-block;
  width: 100%;
  background: rgba(128, 128, 128, 0.15);
  border-radius: 6px;
  padding: 4px 2px;
}

.is-future {
  opacity: 0.5;
}

.school-subject-btn {
  background: $default-school-subject-color;
}

@media screen {
  tr.selected {
    background: rgba(25, 118, 210, 0.12);
  }

  td.selection {
    position: relative;
  }

  td.selection::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border-top: $selection-border;
    border-bottom: $selection-border;
  }

  td.selection-first::before {
    border-left: $selection-border;
    border-top-left-radius: $selection-border-radius;
    border-bottom-left-radius: $selection-border-radius;
  }

  td.selection-last::before {
    border-right: $selection-border;
    border-top-right-radius: $selection-border-radius;
    border-bottom-right-radius: $selection-border-radius;
  }

  .selection-toolbar {
    position: absolute;
    transform: translate(-50%, calc(-100% - 6px));
    transition: none;
  }
}

@media print {
  table {
    width: 100%;
    border: 1.25pt solid #000;

    th, td {
      border: 0.1pt solid #000 !important;
    }

    thead tr:last-child th {
      border-bottom: 1.25pt solid #000 !important;
    }
  }
}
</style>
