<template>
  <div class="table-caption pb-3">

    <v-btn
      :loading="loading"
      icon
      title="Aktualisieren"
      class="d-print-none"
      @click="$emit('refresh')"
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-btn
      icon
      title="Drucken"
      class="mr-4 d-none d-sm-inline-flex d-print-none"
      @click="print()"
    >
      <v-icon>mdi-printer</v-icon>
    </v-btn>

    <v-chip
      label
      outlined
    >
      <v-icon
        left
        small
      >
        mdi-calendar-month-outline
      </v-icon>
      {{ datesText }}
    </v-chip>

    <v-chip
      v-if="isContextSchoolClass"
      label
      outlined
    >
      <v-icon
        left
        small
      >
        mdi-account-group-outline
      </v-icon>
      Klasse:
      <v-chip
        small
        class="ml-1"
      >
        {{ contextObject.code }} {{ contextObject.name }}
      </v-chip>
    </v-chip>

    <v-chip
      v-if="isContextCourse"
      label
      outlined
    >
      <v-icon
        left
        small
      >
        mdi-school-outline
      </v-icon>
      Kurs:
      <v-chip
        small
        class="ml-1"
        :color="schoolSubject.color"
      >
        {{ contextObject.code }}: {{ schoolSubject.name }}
      </v-chip>
    </v-chip>

    <v-chip
      v-if="filterByStudent"
      label
      outlined
    >
      <v-icon
        left
        small
      >
        mdi-filter-outline
      </v-icon>
      Schüler/-in:
      <v-chip
        small
        close
        class="ml-1 d-print-none"
        @click="$emit('clear:filter-by-student')"
        @click:close="$emit('clear:filter-by-student')"
      >
        {{ filterByStudent.displayName }}
      </v-chip>
      <v-chip
        small
        class="ml-1 d-none d-print-flex"
      >
        {{ filterByStudent.displayName }}
      </v-chip>
    </v-chip>

    <v-chip
      v-if="filterBySchoolSubject"
      label
      outlined
    >
      <v-icon
        left
        small
      >
        mdi-filter-outline
      </v-icon>
      Fach:
      <v-chip
        :color="filterBySchoolSubject.color"
        small
        close
        class="ml-1 d-print-none"
        @click="$emit('clear:filter-by-school-subject')"
        @click:close="$emit('clear:filter-by-school-subject')"
      >
        {{ filterBySchoolSubject.name }}
      </v-chip>
      <v-chip
        :color="filterBySchoolSubject.color"
        small
        class="ml-1 d-none d-print-flex"
      >
        {{ filterBySchoolSubject.name }}
      </v-chip>
    </v-chip>

  </div>
</template>


<script>
import { formatDate, dateRangeToString } from '@/helpers/datetime'

export default {
  name: 'AbsencesTableCaption',
  props: {
    startDate: String,
    endDate: String,
    filterByStudent: Object,
    filterBySchoolSubject: Object,
    loading: Boolean
  },
  computed: {
    contextObject() {
      return this.$store.state.contextObject
    },
    isContextSchoolClass() {
      return 'SchoolClass' === this.contextObject?.['@type']
    },
    isContextCourse() {
      return 'Course' === this.contextObject?.['@type']
    },
    datesText() {
      const { startDate, endDate } = this
      if (startDate === endDate) return `Den ${formatDate(startDate)}`
      return dateRangeToString(startDate, endDate)
    },
    schoolSubject() {
      const iri = this.contextObject?.schoolSubject
      return iri && this.$store.getters['common/schoolSubjectByIri'](iri) || {}
    }
  },
  methods: {
    print() {
      window.print()
    }
  }
}
</script>


<style lang="scss" scoped>
.table-caption {
  font-weight: bold;
  padding: 4px 0;

  & > * {
    margin: 4px;
  }
}
</style>
