<template>
  <div>
    <v-menu
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="date | formatDate"
          :label="label"
          readonly
          outlined
          dense
          hide-details
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        :value="date"
        :min="section.startsOn"
        :max="section.endsOn"
        :allowedDates="isDateAllowed"
        first-day-of-week="1"
        no-title
        show-week
        color="primary"
        @change="$emit('change:date', $event)"
      ></v-date-picker>
    </v-menu>
    <v-select
      :value="hourNumber"
      :items="hourNumbers"
      item-value="hourNumber"
      class="mt-3"
      label="Stunde"
      outlined
      dense
      hide-details
      @change="$emit('change:hourNumber', $event)"
    >
      <template v-slot:selection="{ item }">
        <HourNumberSelectItem :item="item" />
      </template>
      <template v-slot:item="{ item }">
        <HourNumberSelectItem :item="item" />
      </template>
    </v-select>
  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import { formatDate } from '@/helpers/datetime'
import HourNumberSelectItem from '@/pages/absences.page/absences-table/hour-number-select-item'

export default {
  name: 'HourNumberSelect',
  components: {
    HourNumberSelectItem
  },
  filters: {
    formatDate
  },
  props: {
    label: String,
    date: String,
    hourNumber: Number
  },
  computed: {
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', ['allowedDates']),
    hourNumbers() {
      const dayPlan = this.$store.getters['common/dayPlanByDate'](this.date)
      return (dayPlan?.dayPlanSlots || []).filter(({ type }) => 'lesson' === type)
    }
  },
  methods: {
    isDateAllowed(date) {
      return this.allowedDates.includes(date)
    }
  }
}
</script>
