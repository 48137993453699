<template>
  <v-toolbar
    class="selection-toolbar d-print-none"
    dense
    floating
    rounded
  >
    <v-btn
      icon
      title="Eintragen"
      :disabled="!canEdit"
      @click="$emit('click:edit')"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn
      icon
      color="error"
      title="Löschen"
      :disabled="!canDelete"
      @click="$emit('click:delete')"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <v-divider
      vertical
      class="mx-1"
    />
    <v-btn
      icon
      small
      title="Schließen"
      @click="$emit('click:close')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
</template>


<script>
export default {
  name: 'SelectionToolbar',
  props: {
    canEdit: Boolean,
    canDelete: Boolean
  }
}
</script>
