<template>
  <v-app-bar
    dense
    color="white"
    class="flex-grow-0 d-print-none"
  >
    <DateRangePicker
      :value="dateInterval"
      :allow-month="'section' !== groupBy"
      :allow-week="!['section', 'month'].includes(groupBy)"
      :allow-day="!['section', 'month', 'week'].includes(groupBy)"
      :loading="loading"
      class="flex-grow-1"
      @input="$emit('input:date-interval', $event)"
    />
  </v-app-bar>
</template>


<script>
import DateRangePicker from '@/components/date-range-picker'

export default {
  name: 'AbsencesTableToolbarBottom',
  components: {
    DateRangePicker
  },
  props: {
    loading: Boolean,
    dateInterval: Object,
    groupBy: String
  }
}
</script>
