<template>
  <component
    offset-y
    min-width="300"
    max-width="430"
    :close-on-content-click="false"
    :is="menuComponent"
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        depressed
        height="32"
        color="primary"
        :disabled="loading"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          :small="!text"
          :left="!!text"
        >
          {{ icon }}
        </v-icon>
        {{ text }}
      </v-btn>
    </template>

    <v-card v-if="menu">
      <v-list class="pt-5">

        <v-list-item>
          <v-select
            v-model="collection"
            :items="collections"
            :disabled="loading"
            item-text="title"
            label="Kontext"
            prepend-icon="mdi-image-filter-center-focus-strong-outline"
            return-object
            outlined
            dense
          >
            <template v-slot:item="{ item }">
              <v-icon>{{ item.icon }}</v-icon>
              <span class="ml-3">{{ item.title }}</span>
            </template>
          </v-select>
        </v-list-item>

        <v-list-item>
          <v-autocomplete
            :value="value"
            :items="contextObjectItems"
            :item-text="autocompleteSearchText"
            :prepend-icon="icon"
            :label="(collection || {}).title"
            :disabled="loading || !collection"
            return-object
            outlined
            dense
            @change="$emit('input', $event); menu = false"
          >

            <template v-slot:selection="{ item }">
              <component
                :is="contextObjectItemComponent"
                :value="item"
              />
            </template>

            <template v-slot:item="{ item }">
              <component
                :is="contextObjectItemComponent"
                :value="item"
              />
            </template>

          </v-autocomplete>
        </v-list-item>

      </v-list>
    </v-card>
  </component>
</template>


<script>
import { VBottomSheet, VMenu } from 'vuetify/lib'
import ItemSchoolClass from '@/components/context-object-select/item-schoolclass'
import ItemCourse from '@/components/context-object-select/item-course'
import ItemClassroom from '@/components/context-object-select/item-classroom'
import ItemTeacher from '@/components/context-object-select/item-teacher'
import contextObjectCollections from '@/store/context-object-collections'

export default {
  name: 'ContextObjectSelect',
  components: {
    VBottomSheet,
    VMenu,
    ItemSchoolClass,
    ItemCourse,
    ItemClassroom,
    ItemTeacher
  },
  props: {
    value: Object,
    filterCollections: Array,
    loading: Boolean
  },
  data: () => ({
    menu: false,
    collection: null
  }),
  computed: {
    collections() {
      if (!this.filterCollections) return contextObjectCollections
      return this.filterCollections.map(fc => contextObjectCollections.find(c => fc === c.collection))
    },
    icon() {
      const type = this.value?.['@type']
      const collection = type && this.collections.find(c => c.type === type) || this.collection
      return collection?.icon || 'mdi-image-filter-center-focus-strong-outline'
    },
    text() {
      return this.value?.code || null
    },
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    menuComponent() {
      return this.mini ? VBottomSheet : VMenu
    },
    contextObjectItems() {
      const { collection } = this.collection || {}
      return collection && this.$store.state.common[collection] || []
    },
    contextObjectItemComponent() {
      const { collection } = this.collection || {}
      return collection && {
        schoolClasses: ItemSchoolClass,
        courses: ItemCourse,
        classrooms: ItemClassroom,
        teachers: ItemTeacher
      }[collection] || null
    }
  },
  watch: {
    menu(menu) {
      if (!menu) return
      const type = this.value?.['@type']
      if (!type) return
      this.collection = this.collections.find(c => c.type === type)
    }
  },
  methods: {
    autocompleteSearchText(item) {
      let schoolSubject

      switch (item['@type']) {
        case 'Teacher':
          return `${item.lastName}, ${item.firstName} (${item.code})`

        case 'SchoolClass':
        case 'Classroom':
          return `${item.code} ${item.name}`

        case 'Course':
          schoolSubject = this.$store.getters['common/schoolSubjectByIri'](item.schoolSubject)
          return `${item.code}: ${schoolSubject.name}`

        default:
          return '?'
      }
    }
  }
}
</script>
