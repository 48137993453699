<template>
  <div
    class="option-course"
    :style="{backgroundColor}"
    v-text="title"
  />
</template>


<script>
export default {
  name: 'ItemCourse',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    schoolSubject() {
      return this.$store.getters['common/schoolSubjectByIri'](this.value.schoolSubject)
    },
    backgroundColor() {
      return this.schoolSubject?.color
    },
    title() {
      const { name } = this.schoolSubject
      return this.value.code + (name ? `: ${name}` : '')
    }
  }
}
</script>


<style lang="scss" scoped>
.option-course {
  border-radius: 20px;
  padding: 4px 8px;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 2px 0;
}
</style>
