<template>
  <div>
    <v-avatar
      size="32"
      color="grey"
    >
      <v-img
        v-if="value.avatar"
        :src="value.avatar"
      />
      <v-icon v-else>mdi-account</v-icon>
    </v-avatar>
    <span
      class="ml-2"
      v-text="`${value.lastName}, ${value.firstName} (${value.code})`"
    />
  </div>
</template>


<script>
export default {
  name: 'ItemTeacher',
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>
