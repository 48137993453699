<template>
  <span
    class="absence-chip"
    :class="{
      'came-later': false === isAbsent && studentAbsence.cameLater > 0,
      'left-earlier': false === isAbsent && studentAbsence.leftEarlier > 0,
      'empty': typeof isAbsent != 'boolean'
    }"
    :style="{borderColor: color}"
    :title="studentAbsence.comment"
  >
    <v-icon
      v-if="showIcon"
      x-small
      v-text="icon"
    />
    <span>{{ code }}</span>
    <v-icon
      v-if="false === isAbsent"
      size="18"
      :color="color"
      class="d-none d-print-inline"
    >mdi-check-circle-outline</v-icon>
  </span>
</template>


<script>
export default {
  name: 'AbsenceChip',
  props: {
    studentAbsence: {
      type: Object,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isAbsent() {
      return this.studentAbsence.isAbsent
    },
    absenceReason() {
      return this.$store.getters['common/absenceReasonByIri'](this.studentAbsence.absenceReason)
    },
    color() {
      if (false === this.isAbsent) {
        return '#8de08d'
      }
      if (true === this.isAbsent) {
        return this.absenceReason?.color || '#f39999'
      }
      return null
    },
    icon() {
      return true === this.isAbsent && this.absenceReason?.icon || null
    },
    code() {
      return true === this.isAbsent && this.absenceReason?.code || null
    }
  }
}
</script>


<style lang="scss" scoped>
.absence-chip {
  display: inline-flex;
  position: relative;
  height: 0;
  width: 0;
  font-size: 9px;
  align-items: center;
  justify-content: center;
  border: 11px solid transparent;
  border-radius: 11px;
  line-height: 1;

  &.came-later::before,
  &.left-earlier::after {
    content: '';
    display: block;
    position: absolute;
    top: -11px;
    width: 0;
    height: 0;
    border-radius: 4px;
    border: 4px solid #f75151;
  }

  &.came-later::before {
    left: -11px;
  }

  &.left-earlier::after {
    right: -11px;
  }

  &.empty {
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}
</style>
